import { useCallback, useEffect } from 'react'
import type * as React from 'react'

import type { StatusType } from '@datadog/browser-logs'
import getConfig from 'next/config'
import dynamic from 'next/dynamic'
import Head from 'next/head'

import type { BStockAppProps } from '@b-stock/bstock-next'
import {
  BStockApp,
  decodeAccessToken,
  useAccessToken,
} from '@b-stock/bstock-next'
import { ddLogger, ErrorBoundary } from '@b-stock/bstock-react'

import theme from '@assets/theme/theme'
import Pendo from '@components/Pendo'
import { FeatureFlagProvider } from '@helpers/featureFlags'
import { useLogError } from '@helpers/useLogError'

import { localeData } from '../locale/localeData'
import '../locale/setYupLocale'

import '../../styles/globals.css'
import 'react-toastify/dist/ReactToastify.min.css'

const DynamicDevTools = dynamic(() => import('./DevTools'), {
  loading: () => null,
})

if (typeof window !== 'undefined') {
  const {
    publicRuntimeConfig: {
      APP_ENV,
      DATADOG_LOGS,
      DATADOG_CLIENT_TOKEN,
      LOGGER_LEVEL,
      BUILD_ID,
    },
  } = getConfig()

  ddLogger.startLogger({
    datadogConfig: {
      clientToken: DATADOG_CLIENT_TOKEN,
      version: BUILD_ID,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['error'],
      sessionSampleRate: 100,
    },
    level: LOGGER_LEVEL as StatusType,
    env: APP_ENV || '',
    enabled: DATADOG_LOGS,
    appName: 'buyer-portal',
  })
}

const BuyerApp = (
  appProps: Omit<
    BStockAppProps,
    'theme' | 'locale' | 'localeData' | 'showLoadingSpinner'
  >
) => {
  const {
    Component,
    pageProps: { featureFlags, launchDarklyClientSideId },
  } = appProps
  const accessToken = useAccessToken()
  const {
    publicRuntimeConfig: { APP_ENV },
  } = getConfig()

  useEffect(() => {
    if (accessToken) {
      const { accountId, personId } = decodeAccessToken(accessToken)
      if (accountId && personId) {
        ddLogger.setGlobalContext({ token: { accountId, personId } })
      }
    }
  }, [accessToken])

  const logError = useLogError()

  const WrappedComponent = useCallback(
    (pageProps: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
      <>
        <Head>
          <title key="page-title">B-Stock</title>
        </Head>
        <FeatureFlagProvider
          featureFlags={featureFlags}
          clientSideId={launchDarklyClientSideId}
        >
          <ErrorBoundary onError={logError}>
            <Component {...pageProps} />
          </ErrorBoundary>
        </FeatureFlagProvider>
        <Pendo apiKey="cde87dbc-c4f3-4439-57d1-4d143cfe94e4" />
        {APP_ENV === 'dev' || APP_ENV === 'qa' ? <DynamicDevTools /> : null}
      </>
    ),
    [APP_ENV, Component]
  )

  return (
    <BStockApp
      {...appProps}
      theme={theme}
      Component={WrappedComponent}
      locale={'en'}
      localeData={localeData}
      showLoadingSpinner
    />
  )
}

export default BuyerApp
